<template>
  <div :class="`col-${columns} m-group-title`">
    <h6 class="text-faded" :class="{ 'text-uppercase': !lowercase }">
      <slot>{{ label }}</slot>
      <slot name="button" />
    </h6>
    <div class="text-body2 text-faded">
      <slot name="sublabel">
        {{ sublabel }}
      </slot>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'MGroupTitle',
  props: {
    label: {
      type: String,
      default: null
    },
    sublabel: {
      type: String,
      default: null
    },
    columns: {
      type: String,
      default: '12'
    },
    lowercase: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
div
  position relative

h6
  margin 0
  font-size 1rem

  @media (max-width 599px)
    margin 16px

.text-uppercase
  text-transform uppercase
</style>
